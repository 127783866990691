import "./LazyLoadImage.css";

import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import S3 from "../../../../services/clients/s3";

type Props = {|
  /** Filename, for alt text. */
  name: string,

  /** Paths to fetch photos from S3. */
  thumbnail: object,

  /** Used to create accurate Skeleton components. */
  width: number,
  height: number,

  /** Callback triggered on photo click. */
  onClick: (number) => {},

  /** Used to identify the image for the onClick callback. */
  index: number,
|};

export default function LazyLoadImage(props: Props) {
  const containerRef = React.useRef(null);

  //  Controls lazy-load on scroll (only load when scrolled into view).
  const [isVisible, setIsVisible] = React.useState(false);
  const [dataURL, setDataURL] = React.useState("");

  //  Controls lazy-load on mouse-over.
  const [isMouseOver, setIsMouseOver] = React.useState(false);
  const [gifURL, setGifURL] = React.useState("");

  React.useEffect(() => {
    const fetchThumbnail = async (path, setter) => {
      setter(await S3.fetchThumbnailURL(path));
    };
    if (isVisible && !dataURL) {
      fetchThumbnail(props.thumbnail.image, setDataURL);
    }
    if (isMouseOver && !gifURL) {
      fetchThumbnail(props.thumbnail.video, setGifURL);
    }

    const observer = new IntersectionObserver(
      (entries) => {
        //  There should only be *one* entry, since we're only following one item.
        //  Also, we don't flip visibility off since once a photo is loaded, it's cached
        //  in memory.
        const [entry] = entries;
        if (!isVisible && (entry.isIntersecting || entry.isVisible)) {
          setIsVisible(true);
        }
      },

      {
        //  The viewport is defined at the global document level.
        root: null,

        //  Set the viewport's bottom margin to be 20px above the bottom of the screen,
        //  so that we can see the images being loaded.
        rootMargin: "0px 0px -20px 0px",

        //  Run the callback as soon as the image hits the viewpoint.
        threshold: 0,
      }
    );

    const ref = containerRef;
    if (ref.current) {
      observer.observe(ref.current);
    }

    //  Clean up when the React element is no longer hydrated.
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [containerRef, isVisible, dataURL, props.thumbnail, isMouseOver, gifURL]);

  if (dataURL) {
    return (
      <Box sx={{ height: props.height }}>
        <button
          onClick={() => props.onClick(props.index)}
          onMouseOver={() => {
            if (!props.thumbnail.video) {
              return;
            }

            setIsMouseOver(true);
          }}
          onMouseOut={() => {
            setIsMouseOver(false);
          }}
        >
          <img
            src={isMouseOver && gifURL ? gifURL : dataURL}
            alt={props.name}
            loading="lazy"
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
            crossOrigin="anonymous"
          />
        </button>
      </Box>
    );
  }
  return (
    <Skeleton
      variant="rectangular"
      height={props.height}
      ref={containerRef}
      sx={{
        //  Matches the parent `ImageList` gap.
        marginBottom: "8px",
      }}
      dataname={props.name}
    />
  );
}
