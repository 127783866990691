import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

type Props = {|
  title: string,
  children?: React.ReactNode,
|};

export default function Section(props: Props) {
  return (
    <Container
      maxWidth={false}
      sx={{ paddingTop: "15px", paddingBottom: "15px" }}
    >
      <Typography variant="h6" color="text.primary" align="left">
        {props.title}
      </Typography>
      <Box
        sx={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "5px",
          position: "relative",
        }}
      >
        {props.children}
      </Box>
    </Container>
  );
}
