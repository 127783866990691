import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link as RouterLink, useLocation } from "react-router-dom";

import GitlabLogo from "./gitlab-logo-500.svg";
import Link from "../Link";

export default function NavHeader() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <AppBar position="static">
      <Toolbar>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkRouter underline="hover" to="/">
            Albums
          </LinkRouter>
          {pathnames.map((value, index) => {
            const isLast = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join("/")}`;

            return isLast ? (
              <Typography variant="h6" key={to} color="text.primary">
                {decodeURI(value)}
              </Typography>
            ) : (
              <LinkRouter underline="hover" to={to} key={to}>
                {decodeURI(value)}
              </LinkRouter>
            );
          })}
        </Breadcrumbs>
        <Box sx={{ position: "absolute", right: "1%" }}>
          <IconButton href="https://gitlab.com/domanchi/react-s3-photo-gallery">
            <img src={GitlabLogo} alt="Gitlab" width={40} height={40} />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

type LinkRouterProps = {
  to: string,
  children: React.ReactNode,
  color?: string,
};

function LinkRouter(props: LinkRouterProps) {
  const linkProps = {
    ...props,
    color: props.color ? props.color : "inherit",
  };

  return (
    <Link {...linkProps} component={RouterLink}>
      <Typography variant="h6">{props.children}</Typography>
    </Link>
  );
}
