import {
  S3Client,
  ListObjectsCommand,
  GetObjectCommand,
  PutObjectCommand,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

import STS from "./sts";
import { AWSRegion, DefaultBucketName, StorageClass } from "../config";
import { trim } from "../util";

/**
 * Lists all files and folders within a given path.
 *
 * @param {string} path path to folder
 * @return {Array} file and folder names. Folder names will have a trailing slash.
 */
export async function listDirectory(path = "/") {
  if (!path.endsWith("/")) {
    path = `${path}/`;
  }
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }

  path = decodeURI(path);

  const client = await getClient();
  const data = await client.send(
    //  For more information, check out:
    //  https://docs.aws.amazon.com/AmazonS3/latest/API/API_ListObjectsV2.html
    new ListObjectsCommand({
      Bucket: getBucketName(),

      //  We list the thumbnails, since they contain metadata about the dimensions
      //  of the photo (so we can perform better lazy loading)
      Prefix: `thumbs${path}`,

      //  This is used so that we don't return the items in the nested folders.
      Delimiter: "/",
    })
  );

  return []
    .concat(
      (data.CommonPrefixes || []).map((item) => item.Prefix),
      (data.Contents || [])
        .map((item) => item.Key)
        .filter((item) => item !== `thumbs${path}`)
    )
    .map((item) => item.substr(`thumbs${path}`.length));
}

export async function fetchThumbnailURL(path, expiry = 3600) {
  const client = await getClient();
  return getSignedUrl(
    client,
    new GetObjectCommand({
      Bucket: getBucketName(),
      Key: `thumbs${decodeURI(path)}`,
    }),
    {
      expiresIn: expiry,
    }
  );
}

/**
 * Fetches a pre-signed URL for a given photo.
 *
 * This is used instead of raw downloading since we use it in conjunction with
 * the lightbox (which needs image URLs).
 *
 * @param {string} path path to photo
 * @param {number} expiry seconds for expiry. Defaults to 60mins as that is the length
 *    of the AWS session. Once the current session expires, all pre-signed URLs created
 *    by that session will also expire (source:
 *    https://docs.aws.amazon.com/AmazonS3/latest/userguide/ShareObjectPreSignedURL.html)
 */
export async function fetchPhotoURL(path, expiry = 3600) {
  const client = await getClient();

  //  Pre-signed URLs are entirely client-side generated. So we can generate one for each
  //  resource, without worrying about lazy loading.
  return getSignedUrl(
    client,
    new GetObjectCommand({
      Bucket: getBucketName(),
      Key: `albums${decodeURI(path)}`,
    }),
    {
      expiresIn: expiry,
    }
  );
}

/**
 * Creates a folder in S3.
 *
 * @param {string} folderName name of newly created folder
 * @param {string} path location to create folder
 * @return {string} path to new folder
 */
export async function createFolder(folderName, path = "/") {
  folderName = trim(folderName, "/");
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }
  if (path.endsWith("/")) {
    path = path.substr(0, path.length - 1);
  }

  path = decodeURI(path);

  const client = await getClient();
  const bucket = getBucketName();
  await Promise.all([
    client.send(
      new PutObjectCommand({
        Bucket: bucket,
        Key: `albums${path}/${folderName}/`,
        ContentLength: 0,
        StorageClass: StorageClass,
      })
    ),
    client.send(
      new PutObjectCommand({
        Bucket: bucket,
        Key: `thumbs${path}/${folderName}/`,
        ContentLength: 0,
        StorageClass: StorageClass,
      })
    ),
  ]);

  return `${path}/${folderName}/`;
}

/**
 * @param {File} file photo to upload
 * @param {string} path folder to store in
 */
export async function uploadPhoto(file, path = "/") {
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }
  if (path.endsWith("/")) {
    path = path.substr(0, path.length - 1);
  }

  path = decodeURI(path);
  return uploadFile(file, `albums${path}/${file.name}`);
}

/**
 * @param {File} file photo to upload
 * @param {string} path folder to store in
 */
export async function uploadThumbnail(file, path = "/") {
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }
  if (path.endsWith("/")) {
    path = path.substr(0, path.length - 1);
  }

  path = decodeURI(path);
  return uploadFile(file, `thumbs${path}/${file.name}`);
}

/**
 * @param {File} file file to upload
 * @param {string} path where to place it
 */
async function uploadFile(file, path) {
  //  NOTE: We don't use MultipartUploads since each part needs to be 5MB or more.
  //  Since the majority of our photos barely exceed that, we don't need it.
  const client = await getClient();
  await client.send(
    new PutObjectCommand({
      Bucket: getBucketName(),
      Key: path,
      Body: file,
      StorageClass: StorageClass,
      ContentType: file.type,
    })
  );

  console.log(`Uploaded: ${path}`);
}

/**
 * Initializes S3Client from saved configurations.
 */
function getClient() {
  return new Promise((resolve, reject) => {
    const credentials = STS.getCachedCredentials();
    if (credentials == null) {
      return reject({
        type: "aws-sdk:s3",
        message: "Authentication Error: Please refresh your credentials.",
      });
    }

    resolve(
      new S3Client({
        region: AWSRegion,
        credentials: {
          accessKeyId: credentials.AccessKeyId,
          secretAccessKey: credentials.SecretAccessKey,
          sessionToken: credentials.SessionToken,
        },
      })
    );
  });
}

/**
 * Fetches bucket name with the following precedence:
 *   - URL Query Parameter: `bucket`
 *   - Default value
 *
 * @returns S3 Bucket Name
 */
function getBucketName() {
  const params = new URLSearchParams(window.location.search);
  const bucketName = params.get("bucket");
  if (bucketName) {
    return bucketName;
  }

  return DefaultBucketName;
}

const functions = {
  listDirectory,
  createFolder,
  uploadThumbnail,
  fetchPhotoURL,
  fetchThumbnailURL,
  uploadPhoto,
};
export default functions;
