import React from "react";

import LoginPrompt from "../LoginPrompt";
import STS from "../../services/clients/sts";

const AuthenticatedContext = React.createContext(undefined);

export function RequireAuthenticatedUser(props) {
  const [promptLogin, setPromptLogin] = React.useState(
    STS.getCachedCredentials() == null
  );

  const originalExceptionHandler = window.onunhandledrejection;
  window.onunhandledrejection = (event) => {
    if (event.reason.type === "aws-sdk:s3") {
      event.preventDefault();
      setPromptLogin(true);
      return;
    }

    if (originalExceptionHandler) {
      return originalExceptionHandler(event);
    }
  }

  const value = React.useMemo(() => {
    return {
      isLoggedIn: !promptLogin,
    };
  }, [promptLogin]);

  return (
    <AuthenticatedContext.Provider value={value}>
      <LoginPrompt open={promptLogin} onSuccess={() => setPromptLogin(false)} />
      {props.children}
    </AuthenticatedContext.Provider>
  );
}

export default function useUserSession() {
  const context = React.useContext(AuthenticatedContext);

  if (!context) {
    throw new Error(
      "useUserSession must be used within a RequireAuthenticatedUser context."
    );
  }

  return context;
}
