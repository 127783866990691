import search from "./search";
import { rsplit } from "../../../services/util";

/**
 * @param {File} file
 * @returns {Array}
 */
export default async function extractMotionPhoto(file) {
  const bytes = await readFile(file);

  //  According to https://github.com/joemck/ExtractMotionPhotos#description-of-motion-photo-file-format
  //  it looks like Samsung motion photos are just two binary files smushed together, delimited by the
  //  magic string: `MotionPhoto_Data`. Therefore, we find this split, and divide the two.
  const magicBytes = Uint8Array.from(
    "MotionPhoto_Data".split("").map((c) => c.charCodeAt(0))
  );
  const offsets = search(bytes, magicBytes);
  if (offsets.length !== 1) {
    if (offsets.length > 1) {
      console.error("Unhandled motion photo type.");
    }
    return [file, null];
  }

  const photo = bytes.slice(0, offsets[0]);
  const video = bytes.slice(offsets[0] + magicBytes.length);

  const [filename] = rsplit(file.name, ".", 1);
  return [
    new File([photo.buffer], file.name, { type: "image/jpeg" }),
    new File([video.buffer], `${filename}.mp4`, { type: "video/mp4" }),
  ];
}

/**
 * @param {File} file
 * @returns {Promise<Uint8Array>}
 */
function readFile(file) {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.onloadend = (e) => {
      if (e.target.readyState !== FileReader.DONE) {
        return;
      }

      return resolve(new Uint8Array(e.target.result));
    };
    reader.readAsArrayBuffer(file);
  });
}
