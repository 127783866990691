import React from "react";

/**
 * Just like Javascript trim, but with arbitrary characters instead.
 *
 * @param {string} x
 * @param {string} characters
 */
export function trim(x, characters = " ") {
  let start = 0;
  while (characters.indexOf(x[start]) >= 0) {
    start++;
  }

  let end = x.length - 1;
  while (characters.indexOf(x[end]) >= 0) {
    end--;
  }

  return x.substr(start, end - start + 1);
}

/**
 * Just like Python's rsplit.
 *
 * @param {string} x
 * @param {string} delimiter
 * @param {number} numParts
 */
export function rsplit(x, delimiter, numParts) {
  const parts = x.split(delimiter);

  return [
    parts.slice(0, -numParts).join(delimiter),
    parts.slice(parts.length - numParts).join(delimiter),
  ];
}

/**
 * Used to get previous values for a given component.
 */
export function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
