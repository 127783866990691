import "./Page.css";

import React from "react";

import Masonry from "@mui/lab/Masonry";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FsLightbox from "fslightbox-react";
import { createFFmpeg } from "@ffmpeg/ffmpeg";

import CreateFolder from "./components/CreateFolder";
import DynamicDropZone from "./components/DynamicDropZone";
import Folder from "./components/Folder";
import LazyLoadImage from "./components/LazyLoadImage";
import ProgressBar from "./components/ProgressBar";
import Section from "./components/Section";
import UploadImagePlaceholder from "./components/UploadImagePlaceholder";
import extractMotionPhoto from "./services/MotionPhotoExtractor";
import ThumbnailFactory from "./services/ThumbnailFactory";
import { DragDetector } from "../../components/DragDetector";
import useUserSession from "../../components/RequireAuthenticatedUser";
import S3 from "../../services/clients/s3";
import { rsplit, usePrevious } from "../../services/util";

type Props = {|
  path: string,
|};

export default function Page({ path }: Props) {
  const { isLoggedIn } = useUserSession();
  const prevPath = usePrevious(path);
  const wasLoggedIn = usePrevious(isLoggedIn);

  //  Caches the folder listing.
  const [folders, setFolders] = React.useState([]);
  const [photos, setPhotos] = React.useState([]);

  //  Used to track in-progress file uploads
  const [uploadQueue, setUploadQueue] = React.useState([]);
  const [uploadIndex, setUploadIndex] = React.useState(0);

  const [showLightbox, setShowLightbox] = React.useState(false);
  const [lightboxIndex, setLightboxIndex] = React.useState(0);

  const [uploadProgress, setUploadProgress] = React.useState(null);
  const [ffmpeg, setFFmpeg] = React.useState(null);

  React.useEffect(() => {
    const loadFFmpeg = async () => {
      const tmp = createFFmpeg({
        corePath: "https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js",
        progress: ({ ratio }) => {
          //  ratio is a value between 0-1.
          //  NOTE: This initially also gives you an estimated duration in seconds, if
          //  this is useful in the future.
          if (0 < ratio && ratio < 1) {
            setUploadProgress(Math.round(ratio * 100));
          } else {
            setUploadProgress(null);
          }
        },
      });

      await tmp.load();
      setFFmpeg(tmp);
    };

    loadFFmpeg();
  }, []);

  React.useEffect(() => {
    if (uploadQueue.length) {
      //  All photos uploaded, we can reset our queue.
      if (uploadIndex >= uploadQueue.length) {
        setUploadQueue([]);
        setUploadIndex(0);
        return;
      }

      //  Upload both thumbnail and raw concurrently.
      const uploader = async () => {
        const photo = await uploadFile(uploadQueue[uploadIndex], path, {
          ffmpeg,
        });
        photo.url = await S3.fetchPhotoURL(photo.path);
        setPhotos(photos.concat(photo));
        setUploadIndex(uploadIndex + 1);
      };

      uploader();
      return;
    }

    //  We want this to run *once* per new page, and be re-triggered by
    //  a fresh login session. If we have uploaded photos, we would populate
    //  the photos locally, and avoid this trigger.
    if (
      wasLoggedIn !== isLoggedIn ||
      prevPath !== path ||
      (folders.length === 0 && photos.length === 0)
    ) {
      const fetchItems = async () => {
        const { folders, files } = await getFilesAndFolders(path);
        setFolders(folders);
        setPhotos(files);
      };

      fetchItems();
    }
  }, [
    prevPath,
    path,
    uploadQueue,
    uploadIndex,
    wasLoggedIn,
    isLoggedIn,
    folders.length,
    photos,
    ffmpeg,
  ]);

  return (
    <DragDetector>
      <FsLightbox
        toggler={showLightbox}
        //  NOTE: From investigation, it looks like this performs adequate lazy loading.
        //  The images are only fetched from S3 for the current, previous and next photos.
        sources={photos.map((photo) => photo.url)}
        sourceIndex={lightboxIndex}
        customAttributes={photos.map((photo) => ({
          alt: photo.name,
          crossOrigin: "anonymous",
        }))}
        //  FsLightbox doesn't do a good job at refreshing props. Therefore, we need to
        //  force a refresh by causing the component to re-render when the photos array
        //  has changed.
        key={photos}
      />
      <div className="app">
        <Section title="Folders">
          <Grid container spacing={2}>
            {folders.map((item, index) => (
              <Grid item key={item.link}>
                <Folder name={item.name} to={item.link} />
              </Grid>
            ))}
            <Grid item key="create">
              <CreateFolder
                onCreate={async (name) => {
                  const newFolderPath = await S3.createFolder(name, path);
                  setFolders(
                    folders.concat({
                      name: name,
                      link: newFolderPath,
                    })
                  );
                }}
              />
            </Grid>
          </Grid>
        </Section>
        <Section title="Photos">
          <DynamicDropZone
            forceShow={photos.length === 0 || uploadQueue.length > 0}
            enableClick={photos.length === 0}
            active={uploadQueue.length > 0}
            onDrop={(files) => {
              setUploadQueue(uploadQueue.concat(files));
            }}
          >
            <Typography
              variant="h6"
              color="text.primary"
              sx={{ position: "relative", paddingBottom: "8px" }}
            >
              {uploadQueue.length > uploadIndex
                ? `Uploading ${uploadIndex + 1} / ${
                    uploadQueue.length
                  } files: ` + uploadQueue[uploadIndex].name
                : "Click or drop files here to upload..."}
            </Typography>
            {uploadQueue.length > uploadIndex && (
              <ProgressBar
                value={
                  uploadProgress !== null
                    ? uploadProgress
                    : (uploadIndex / uploadQueue.length) * 100
                }
              />
            )}
          </DynamicDropZone>
          <Masonry columns={{ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }} spacing={1}>
            {photos.map((photo, index) => {
              return (
                <LazyLoadImage
                  {...photo}
                  onClick={(index) => {
                    setShowLightbox(!showLightbox);
                    setLightboxIndex(index);
                  }}
                  index={index}
                  key={photo.name}
                />
              );
            })}
            {photos.length > 0 && (
              <UploadImagePlaceholder
                onClick={(files) => {
                  setUploadQueue(uploadQueue.concat(files));
                }}
              />
            )}
          </Masonry>
        </Section>
      </div>
    </DragDetector>
  );
}

/**
 * @param {string} path
 */
async function getFilesAndFolders(path) {
  //  Remove the trailing slash.
  path = path.endsWith("/") ? path.slice(0, path.length - 1) : path;

  const items = await S3.listDirectory(path);
  const folders = items
    .filter((item) => item.endsWith("/"))
    .map((folder) => ({
      name: folder.substr(0, folder.length - 1),
      link: `${path}/${folder}`,
    }));

  const groupedFiles = items
    .filter((item) => !item.endsWith("/"))
    .reduce(
      //  Group by filename, agnostic to extension.
      (prevValue, currentValue) => {
        const [filename] = rsplit(currentValue, ".", 1);
        prevValue[filename] = prevValue[filename] || [];
        prevValue[filename].push(currentValue);
        return prevValue;
      },
      {}
    );
  const files = Object.entries(groupedFiles).map(([filename, paths]) => {
    //  Extract dimensions of photo out from filename.
    //  Assumes filename in form of: `${filename}.${width}x${height}.${extension}`
    const [basename, dimensions] = rsplit(filename, ".", 1);
    const [width, height] = dimensions
      .split("x")
      .map((x) => Number.parseInt(x));

    //  NOTE: Assumes that there is at most two thumbnails, and one of them is a `gif`.
    const thumbnail = {};
    for (const p of paths) {
      const [, extension] = rsplit(p, ".", 1);
      if (extension === "gif") {
        thumbnail.video = `${path}/${p}`;
      } else {
        thumbnail.image = `${path}/${p}`;
      }
    }

    const [, extension] = rsplit(thumbnail.image, ".", 1);

    return {
      name: basename,
      path: `${path}/${basename}.${extension}`,
      thumbnail: thumbnail,
      width: width,
      height: height,
    };
  });

  //  We need to use this pattern since the `items.map` operation is synchronous,
  //  and we don't want `files` to be an array of Promises. Therefore, we have the
  //  map return objects, and convert them in the asynchronous parent operation.
  await (async (files) => {
    for (let file of files) {
      file.url = await S3.fetchPhotoURL(file.path);
    }
  })(files);

  return { folders, files };
}

/**
 * Uploads raw photo and thumbnail concurrently.
 * @param {File} file
 * @param {string} path
 * @param {object} options has the following parameters:
 *   - ffmpeg: used to convert video files
 */
async function uploadFile(file, path, options) {
  const [photo, video] = await extractMotionPhoto(file);

  const originalDimensions = await ThumbnailFactory.getDimensions(photo);
  const dimensions = {
    width: ThumbnailFactory.DefaultWidth,
    height:
      (originalDimensions.height / originalDimensions.width) *
      ThumbnailFactory.DefaultWidth,
  };

  const thumbnail = {
    image: undefined,
    video: undefined,
  };
  const operations = [
    //  Uploads the original photo
    S3.uploadPhoto(photo, path),

    //  This handles the creation of a static thumbnail, extracted from the photo.
    ThumbnailFactory.createThumbnail(photo, dimensions).then((file) => {
      thumbnail.image = `${path}${file.name}`;
      return S3.uploadThumbnail(file, path);
    }),
  ];

  if (video) {
    operations.push(S3.uploadPhoto(video, path));
    operations.push(
      ThumbnailFactory.createThumbnail(video, {
        ffmpeg: options.ffmpeg,
        ...dimensions,
      }).then((file) => {
        if (file.size === 0) {
          console.error("Unable to create video thumbnail.");
          return;
        }

        thumbnail.video = `${path}${file.name}`;
        return S3.uploadThumbnail(file, path);
      })
    );
  }

  await Promise.all(operations);
  return {
    name: rsplit(file.name, ".", 1)[0],
    path: `${path}${file.name}`,
    thumbnail,
    ...dimensions,
  };
}
