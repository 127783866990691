import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import Box from "@mui/material/Box";

import useDragDetector from "../../../../components/DragDetector";

type Props = {
  /**
   * By default, this component would only show when the user drags a file in.
   * However, we can enable it to always show (e.g. when there are no images)
   */
  forceShow: boolean,

  /**
   * Allows the dropzone to be able to be clicked (to display file browser uploader).
   */
  enableClick: boolean,

  /**
   * Used for styling; determines whether to show the "active" style.
   */
  active: boolean,

  /**
   * Callback for handling dropped files within the component.
   */
  onDrop: ([string]) => {},
};

export default function DynamicDropZone(props: Props) {
  //  Setup the drag detector so that we can change the display when we drag a file
  //  onto anywhere on the page, rather than just the dropzone.
  //  In contrast, `isDragActive` is only true when the file is over the dropzone.
  const isDragging = useDragDetector();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: props.onDrop,
    noKeyboard: true,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    noClick: !props.enableClick,
  });

  const style = useMemo(
    () => ({
      ...styles.base,
      ...(isDragging || props.forceShow ? styles.isEnabled : {}),
      ...(isDragActive || props.active ? styles.isActive : {}),

      //  Use this flag as a proxy for determining whether there are no photos.
      ...(props.enableClick ? styles.isForced : {}),
    }),
    [isDragging, isDragActive, props.forceShow, props.enableClick, props.active]
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <Box
        sx={{
          width: "40%",
          height: "fit-content",
          position: "sticky",
          margin: "auto",
          top: "50%",
          left: 0,
          right: 0,
          bottom: "50%",
        }}
      >
        {props.children}
      </Box>
    </div>
  );
}

DynamicDropZone.defaultProps = {
  forceShow: false,
  enableClick: false,
};

const styles = {
  base: {
    height: "100%",
    width: "100%",
    position: "absolute",
    display: "none",

    //  This should appear above the photos
    zIndex: "1",
    backgroundColor: "rgba(5, 5, 5, 0.4)",
    opacity: 0,

    textAlign: "center",

    transition: "opacity .1s ease-out",
  },
  isEnabled: {
    opacity: 1,
    display: "block",
  },
  isActive: {
    backgroundColor: "rgba(5, 5, 5, 0.7)",
  },
  isForced: {
    height: "50vh",
    width: "97%",
    marginTop: "0.5rem",
    opacity: 1,
  },
};
