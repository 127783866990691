import { rsplit } from "../../../services/util";

const URL = window.URL || window.webkitURL;

class ThumbnailFactory {
  constructor() {
    this.resizeableCanvas = null;
    this.thumbnail = null;

    /**
     * This is an approximate size (in pixels) after selecting a reasonable number of
     * ImageGrid columns for a given screen size.
     *
     * We want to affix the width, since the disparate heights create the nice "tiling"
     * effect.
     */
    this.DefaultWidth = 240;
  }

  /**
   * Creates a thumbnail of a given image.
   *
   * @param {object} options has the following parameters:
   *   - width: desired width of thumbnail
   *   - height: desired height of thumbnail
   *   - compression: (optional) number between 0 and 1; lower number == more pixelation
   *   - ffmpeg: (optional) if provided, is able to convert video file to gif
   *
   * @return {Promise} File for newly created thumbnail
   */
  createThumbnail(file, options) {
    this.resizeableCanvas =
      this.resizeableCanvas || document.createElement("canvas");

    //  We want to place the dimensions within the filename (rather than
    //  metadata) since we want to be able to get it without incurring an
    //  additional GET call to retrieve metadata.
    const [filename] = rsplit(file.name, ".", 1);
    const dimensions = `${Math.round(options.width)}x${Math.round(
      options.height
    )}`;
    const name = `${filename}.${dimensions}`;

    if (file.type.split("/")[0] === "video" && options.ffmpeg) {
      return createGifThumbnail(file, { name, ...options });
    } else {
      return createImageThumbnail(file, {
        name,
        canvas: this.resizeableCanvas,
        ...options,
      });
    }
  }

  /**
   * Gets the original dimensions of the image.
   */
  getDimensions(file) {
    const img = new Image();

    const output = new Promise((resolve) => {
      img.onload = () => {
        URL.revokeObjectURL(img.src);
        resolve({
          width: img.width,
          height: img.height,
        });
      };
    });

    img.src = URL.createObjectURL(file);

    return output;
  }
}

/**
 * @param {File} file
 * @param {object} options has the following parameters:
 *   - name: name of resulting file
 *   - canvas: HTMLElement
 *   - width: desired width of thumbnail
 *   - height: desired height of thumbnail
 *   - compression: (optional) number between 0 and 1; lower number == more pixelation
 * @returns {Promise} thumbnail file.
 */
function createImageThumbnail(file, options) {
  //  The idea for this function is to leverage a `canvas` element to draw
  //  and compress an image in memory. Based off:
  //  https://betterprogramming.pub/generating-thumbnails-dynamically-on-the-client-12637cfe6a97
  const canvas = options.canvas;
  [canvas.width, canvas.height] = [options.width, options.height];

  const ctx = canvas.getContext("2d");

  const img = new Image();
  const output = new Promise((resolve) => {
    const resolver = (blob) => {
      //  Clear memory, since we don't need this anymore.
      URL.revokeObjectURL(img.src);

      const [, extension] = rsplit(file.name, ".", 1);
      resolve(
        new File([blob], `${options.name}.${extension}`, { type: "image/jpeg" })
      );
    };

    img.onload = () => {
      ctx.drawImage(img, 0, 0, options.width, options.height);
      canvas.toBlob(resolver, "image/jpeg", options.compression || 0.5);
    };
  });

  img.src = URL.createObjectURL(file);

  return output;
}

/**
 * Creates a gif from an MP4 video.
 * @param {File} video mp4 video
 * @param {object} options has the following parameters:
 *   - name: name of resulting file
 *   - ffmpeg: initialized FFmpeg object
 *   - width: desired width of thumbnail
 *   - height: desired height of thumbnail
 * @returns {File}
 */
async function createGifThumbnail(video, options) {
  const ffmpeg = options.ffmpeg;
  ffmpeg.FS("writeFile", "tmp.mp4", new Uint8Array(await video.arrayBuffer()));

  await ffmpeg.run(
    //  Input file name
    "-i",
    "tmp.mp4",
    //  Sequence of connected filters to process the video.
    //  For more information, check out:
    //    - https://ffmpeg.org/ffmpeg-filters.html#Filtergraph-syntax-1
    //    - https://ffmpeg.org/ffmpeg-filters.html#scale
    //    - https://superuser.com/a/556031
    //    - https://superuser.com/a/1695537
    "-filter",
    `scale=${options.width}:${options.height}:flags=lanczos,split[s0][s1];[s0]palettegen=max_colors=64[p];[s1][p]paletteuse=dither=bayer`,
    //  Output as gif
    "-f",
    "gif",
    //  No looping (0 is infinite, and N means it will play N+1 times)
    "-l",
    "-1",
    //  Output filename
    "tmp.gif"
  );

  const data = ffmpeg.FS("readFile", "tmp.gif");
  ffmpeg.FS("unlink", "tmp.mp4");
  ffmpeg.FS("unlink", "tmp.gif");
  return new File([data.buffer], `${options.name}.gif`, { type: "image/gif" });
}

export default new ThumbnailFactory();
