import { STS } from "@aws-sdk/client-sts";
import Cookies from "js-cookie";

import { WriterRoleARNPrefix, DefaultWriterRole, AWSRegion } from "../config";

const COOKIE_NAME = "awsRole";

export function getCachedCredentials() {
  const value = Cookies.get(COOKIE_NAME);
  if (!value) {
    return null;
  }

  return JSON.parse(value);
}

/**
 * If successful, promise will resolve with the following structure:
 * {
 *   "AccessKeyId": "ASIA...",
 *   "SecretAccessKey": "<redacted>",
 *   "SessionToken": "<redacted>",
 *   "Expiration": <Date>,
 * }
 *
 * It will also cache the assumed role temporary credentials to cookies.
 */
export function getAccessToBucket({ accessKeyId, accessKeySecret }) {
  return new Promise((resolve, reject) => {
    new STS({
      apiVersion: "2011-06-15",
      region: AWSRegion,
      credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: accessKeySecret,
      },
    }).assumeRole(
      {
        RoleArn: getRoleName(),
        RoleSessionName: "BrowserClient",
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }

        Cookies.set(
          COOKIE_NAME,
          JSON.stringify({
            AccessKeyId: data.Credentials.AccessKeyId,
            SecretAccessKey: data.Credentials.SecretAccessKey,
            SessionToken: data.Credentials.SessionToken,
          }),
          {
            //  By default, this only lasts an hour.
            expires: data.Credentials.Expiration,
            secure: true,
            sameSite: "strict",
          }
        );

        return resolve(data.Credentials);
      }
    );
  });
}

/**
 * Fetches bucket name with the following precedence:
 *   - URL Query Parameter: `bucket`
 *   - Default value
 *
 * @returns S3 Bucket Name
 */
function getRoleName() {
  const params = new URLSearchParams(window.location.search);
  const bucketName = params.get("bucket");
  if (bucketName) {
    return `${WriterRoleARNPrefix}/${bucketName}`;
  }

  return `${WriterRoleARNPrefix}/${DefaultWriterRole}`;
}

const functions = {
  getAccessToBucket,
  getCachedCredentials,
};
export default functions;
