import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import NavHeader from "./components/NavHeader";
import Palette from "./components/Palette";
import { RequireAuthenticatedUser } from "./components/RequireAuthenticatedUser";
import OriginalPage from "./scenes/Page";

export default function App() {
  return (
    <Palette>
      <RequireAuthenticatedUser>
        <BrowserRouter>
          <NavHeader />
          <Routes>
            <Route path="*" element={<Page />} />
          </Routes>
        </BrowserRouter>
      </RequireAuthenticatedUser>
    </Palette>
  );
}

function Page() {
  const { pathname: path } = useLocation();
  return <OriginalPage path={path} />;
}
