import "../../../../styles/dotted-border.css";

import React from "react";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

type Props = {
  onCreate: (string) => {},
};

export default function CreateFolder(props: Props) {
  const [isEditable, setIsEditable] = React.useState(false);
  const [folderName, setFolderName] = React.useState("");
  const [isErrorState, setErrorState] = React.useState(false);

  //  NOTE: There's a strange bug that occurs after we submit the createFolder request.
  //  The ripple effect gets stuck, since the `CardActionArea` has the class
  //  `.Mui-focusVisible` unremoved. Therefore, we need to add a ref to it ourselves,
  //  and manually clear this state.
  const rippleRef = React.useRef(null);

  const createFolder = (name) => {
    if (!name) {
      setErrorState(true);
      return;
    }

    props.onCreate(name);

    setIsEditable(false);
    setFolderName("");
    setErrorState(false);
  };

  return (
    <Card
      sx={{ minWidth: 180, backgroundColor: "unset" }}
      className="dotted-border"
    >
      <CardActionArea
        onClick={() => {
          setIsEditable(true);
          rippleRef.current?.classList.remove("Mui-focusVisible");
        }}
        onBlur={(e) => {
          //  Only trigger if focus isn't switched between child elements.
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setIsEditable(false);
            setFolderName("");
          }
        }}
        ref={rippleRef}
      >
        <CardContent sx={{ textAlign: "center" }}>
          <CreateNewFolderIcon />
          {isEditable ? (
            <TextField
              label="Folder Name"
              variant="outlined"
              autoFocus
              size="small"
              margin="none"
              color={isErrorState ? "error" : "primary"}
              //  Managed values, since we want to handle form submission ourselves.
              value={folderName}
              onChange={(e) => {
                setFolderName(e.target.value);
              }}
              //  Handle submission input.
              placeholder="Press Enter to Create"
              onKeyPress={(e) => {
                if (e.code !== "Enter") {
                  return;
                }

                rippleRef.current?.classList.remove("Mui-focusVisible");
                createFolder(folderName);
              }}
              //  Styling to make it fit in the grid item.
              sx={{
                display: "block",
                height: 24,
                width: 148,
              }}
              InputProps={{
                sx: {
                  height: "2rem",
                  fontSize: "1rem",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "0.84rem",
                },
              }}
            />
          ) : (
            <Typography>Create Folder</Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
