import React from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import STS from "../../services/clients/sts";

type Props = {|
  /** True if the prompt is visible. */
  open: boolean,

  /** Callback to run on successful login. */
  onSuccess: () => {},
|};

export default function LoginPrompt(props: Props) {
  const [isPasswordVisible, setPasswordVisibility] = React.useState(false);
  const [accessKeyId, setAccessKeyId] = React.useState("");
  const [secretAccessKey, setSecretAccessKey] = React.useState("");
  const [error, setError] = React.useState("");

  const handleClose = () => {
    if (!accessKeyId || !secretAccessKey) {
      setError("Please fill in your username and password.");
      return;
    }

    STS.getAccessToBucket({
      accessKeyId: accessKeyId,
      accessKeySecret: secretAccessKey,
    })
      .then(() => {
        props.onSuccess();
      })
      .catch((err) => {
        setError(`${err.name}: ${err.message}`);
      });
  };

  const handleClickShowPassword = () => {
    setPasswordVisibility(!isPasswordVisible);
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>Login</DialogTitle>
      <DialogContent>
        {error && <Alert severity="error">{error}</Alert>}
        <DialogContentText>
          Please login to view and upload photos.
        </DialogContentText>
        <TextField
          id="aws-access-key-id"
          label="Username"
          value={accessKeyId}
          onChange={(event) => setAccessKeyId(event.target.value)}
          placeholder="AKIAEXAMPLEKEY"
          helperText="AWS Access Key ID"
          required
          autoFocus
          fullWidth
          variant="filled"
          margin="normal"
        />
        <TextField
          id="aws-secret-access-key"
          label="Password"
          value={secretAccessKey}
          onChange={(event) => setSecretAccessKey(event.target.value)}
          helperText="AWS Secret Access Key"
          type={isPasswordVisible ? "text" : "password"}
          required
          fullWidth
          variant="filled"
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Login</Button>
      </DialogActions>
    </Dialog>
  );
}
