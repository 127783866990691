import FolderIcon from "@mui/icons-material/Folder";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";

import { getLink } from "../../../../components/Link";

type Props = {
  name: string,
  to: string,
};

export default function Folder(props: Props) {
  const location = useLocation();

  return (
    <Card raised sx={{ minWidth: 180 }}>
      <CardActionArea component={Link} to={getLink(location, props.to)}>
        <CardContent sx={{ textAlign: "center" }}>
          <FolderIcon />
          <Typography>{props.name}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
