//  This file is used for setting global settings for your own use case.

/**
 * This is the default region for your AWS operations.
 */
export const AWSRegion = "us-east-2";

/**
 * The bucket name where all photos are saved.
 */
export const DefaultBucketName = "s3album-prod";

/**
 * This variable is the prefix to the writer role used if the bucket name is specified.
 */
export const WriterRoleARNPrefix = "arn:aws:iam::419181973132:role";

/**
 * This variable declares the role that the current user needs to adopt, in order
 * to access the S3 bucket.
 */
export const DefaultWriterRole = "s3album";

/**
 * This defines the S3 storage class used when uploading photos.
 * For valid values, check out:
 * https://docs.aws.amazon.com/AmazonS3/latest/API/API_PutObject.html
 */
export const StorageClass = "STANDARD_IA";
