import React from "react";
import OriginalLink, { LinkProps } from "@mui/material/Link";
import { Location, useLocation } from "react-router-dom";

export default function Link(props: LinkProps) {
  const location = useLocation();

  return (
    <OriginalLink {...{ ...props, to: getLink(location, props.to) }}>
      {props.children}
    </OriginalLink>
  );
}

export function getLink(location: Location, url: string): string {
  const bucketName = new URLSearchParams(location.search).get("bucket");
  if (bucketName) {
    const tmp = new URL(`${window.location.origin}${url}`);
    tmp.searchParams.append("bucket", bucketName);
    url = tmp.pathname + tmp.search;
  }

  return url;
}
