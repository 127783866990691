/**
 * Implements Boyer-Moore string search algorithm, as defined in
 * https://en.wikipedia.org/wiki/Boyer%E2%80%93Moore_string-search_algorithm
 *
 * @param {Uint8Array} text item to search in
 * @param {Uint8Array} pattern search pattern to look for
 * @param {number} start optional index to start at
 * @returns {[number]}
 */
export default function search(text, pattern) {
  if (!text || !pattern || text.length < pattern.length) {
    return -1;
  }

  //  Preprocessing step.
  const badCharacterTable = createBadCharacterTable(pattern);
  const goodSuffixTable = createGoodSuffixTable(pattern);
  const fullShiftTable = createFullShiftTable(pattern);

  const output = [];

  let index = pattern.length - 1; //  Alignment of end of pattern, relative to text.
  let previousIndex = -1; //  Alignment of previous phase (Galil's Rule)
  while (index < text.length) {
    let patternIndex = pattern.length - 1;
    let textIndex = index;

    //  Work backwards, checking for substring matches.
    while (
      patternIndex >= 0 &&
      textIndex > previousIndex &&
      pattern[patternIndex] === text[textIndex]
    ) {
      patternIndex--;
      textIndex--;
    }

    //  Match has been found (Galil's Rule)
    if (patternIndex === -1 || textIndex === previousIndex) {
      output.push(index - pattern.length + 1);
      index += pattern.length === 1 ? 1 : pattern.length - fullShiftTable[1];
      continue;
    }

    //  Need to shift characters based on preprocessing step.
    //  First, we get the number of characters we would skip if we used the "Bad Character Rule"
    const charShift =
      patternIndex - badCharacterTable[text[textIndex]][patternIndex];

    let suffixShift;
    if (patternIndex + 1 === pattern.length) {
      //  Mismatch happened on first attempt
      suffixShift = 1;
    } else if (goodSuffixTable[patternIndex + 1] === -1) {
      //  Matched suffix does not appear anywhere in the pattern.
      suffixShift = pattern.length - fullShiftTable[patternIndex + 1];
    } else {
      //  Matched suffix appears in the pattern.
      suffixShift = pattern.length - 1 - goodSuffixTable[patternIndex + 1];
    }

    const shift = Math.max(charShift, suffixShift);

    //  Galil's Rule
    previousIndex = shift >= patternIndex + 1 ? index : previousIndex;
    index += shift;
  }

  return output;
}

/**
 * @param {Uint8Array} pattern
 */
function createBadCharacterTable(pattern) {
  const output = [...Array(256).keys()].map((_) => [-1]);
  const alpha = [...Array(256).keys()].map((_) => -1);
  for (let i = 0; i < pattern.length; i++) {
    alpha[pattern[i]] = i;
    for (let j = 0; j < alpha.length; j++) {
      output[j].push(alpha[j]);
    }
  }

  return output;
}

/**
 * @param {Uint8Array} pattern
 */
function createGoodSuffixTable(pattern) {
  const output = [...Array(pattern.length).keys()].map((_) => -1);
  const Z = preprocess(pattern.slice().reverse()).reverse();

  for (let j = 0; j < Z.length - 1; j++) {
    let i = Z.length - Z[j];
    if (i !== Z.length) {
      output[i] = j;
    }
  }

  return output;
}

/**
 * @param {Uint8Array} pattern
 */
function createFullShiftTable(pattern) {
  const output = [...Array(pattern.length).keys()].map((_) => 0);
  const Z = preprocess(pattern).reverse();

  let longest = 0;
  for (let i = 0; i < Z.length; i++) {
    longest = Z[i] === i + 1 ? Math.max(Z[i], longest) : longest;
    output[Z.length - i - 1] = longest;
  }

  return output;
}

/**
 * Creates a preprocessed interim state that is used to generate other preprocessed derivatives.
 *
 * @param {Uint8Array} pattern
 * @returns {[number]} A given index represents the length of a substring, beginning at i,
 *   which is also a prefix of the pattern.
 */
function preprocess(pattern) {
  if (pattern.length === 0) {
    return [];
  }

  const output = Array.from(pattern.map((_) => 0));

  //  At index 0, the "substring" is the entire string. Therefore, the prefix is the
  //  entire pattern.
  output[0] = pattern.length;
  if (pattern.length === 1) {
    return output;
  }

  //  This number directly correlates with the number of duplicated characters at the
  //  beginning of the pattern.
  //    e.g. "ABC"     == 0
  //         "AAABC"   == 2
  //         "AAAAABC" == 4
  //  Therefore, we can quickly fill in these initial numbers with descending values.
  output[1] = getSubstringMatchLength(pattern, 0, 1);
  if (output[1]) {
    for (let i of [...Array(output[1] - 1).keys()].map((n) => n + 2)) {
      output[i] = output[1] - i + 1;
    }
  }

  let left = 0;
  let right = 0;
  for (let i = output[1] + 2; i < pattern.length; i++) {
    if (i <= right) {
      //  Yeah...I don't fully understand the magic here.
      let b = output[i - left];
      let a = right - i + 1;
      if (b < a) {
        output[i] = b;
      } else {
        output[i] = a + getSubstringMatchLength(pattern, a, right + 1);
        left = i;
        right = i + output[i] - 1;
      }
    } else {
      //  We define the left and right bounds by the prefix length that we just found.
      output[i] = getSubstringMatchLength(pattern, 0, i);
      if (output[i] > 0) {
        left = i;
        right = i + output[i] - 1;
      }
    }
  }

  return output;
}

/**
 * For two substrings within `pattern` (defined by the start places `indexA` and `indexB`),
 * measure the length of their match.
 *
 * e.g. getSubstringMatchLength("ABCABC", 0, 3) === 3
 *      getSubstringMatchLength("ABCABC", 0, 1) === 0
 *      getSubstringMatchLength("ABCABC", 1, 4) === 2
 *
 * @param {Uint8Array} pattern
 * @param {number} indexA
 * @param {number} indexB
 * @returns {number}
 */
function getSubstringMatchLength(pattern, indexA, indexB) {
  if (indexA === indexB) {
    return pattern.length - indexA;
  }

  let length = 0;
  while (
    indexA < pattern.length &&
    indexB < pattern.length &&
    pattern[indexA] === pattern[indexB]
  ) {
    length++;
    indexA++;
    indexB++;
  }

  return length;
}
