import "../../../../styles/dotted-border.css";

import React from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

type Props = {
  onClick: (files: [File]) => void,
};

export default function UploadImagePlaceholder(props: Props) {
  return (
    <Box>
      <Card
        //  TODO: move the css from CreateFolder.css to a common location.
        className="dotted-border"
        sx={{
          width: 240, //  Matches default width for thumbnails
          height: 170,
          backgroundColor: "unset",
        }}
      >
        <CardActionArea
          onClick={() => {
            let input = document.createElement("input");
            input.type = "file";
            input.accept = "image/*,.jpeg,.jpg,.png";
            input.multiple = true;

            input.onchange = (event) => {
              props.onClick(Array.from(event.target.files));
            };

            input.click();
          }}
          sx={{
            //  NOTE: This needs to explicitly override the parent Masonry button CSS.
            paddingBottom: "20px !important",
          }}
        >
          <CardContent sx={{ textAlign: "center" }}>
            <Typography variant="h2">+</Typography>
            <Typography>Add Photo</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}
