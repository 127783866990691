import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";

import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blue, pink } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: blue,
    secondary: pink,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#0A1929",
        },
      },
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
  },
});

type Props = {|
  children: React.ReactNode,
|};

export default function Palette(props: Props) {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
